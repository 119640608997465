<script>
export default {
    props:
    {
        template:
        {
            type: String,
            default: "#vue-heista-stepper"
        },
        initStep:
        {
          type: Number,
          default: 1
        },
        isBasket:
        {
            type: Boolean,
            default: false
        }
    },
    data() {
      return {
        activeStep: this.initStep,
        isAddressValid: true,
        isModalOpen: false
      }
    },
    computed: {
      ...Vuex.mapState({
            billingAddress: state => state.address.billingAddress
      })
    },
    watch: { 
        billingAddress(newVal, oldVal) { 
          if(!this.isBasket) {
            this.isAddressValid = this.validateAddress(newVal);
          }
        }
    },
    mounted () {
      var isCheckoutModalDone = sessionStorage.getItem("isCheckoutModalDone");

      if(!isCheckoutModalDone) {
        this.isModalOpen = true;
      } else if(this.activeStep == 1.5) {
        this.activeStep = 2;
      }

      if(!this.isBasket) {
        this.isAddressValid = this.validateAddress(this.billingAddress);
      }
     
    },
    methods:
    {
        changeStep(newStep)
        {
          if(this.isBasket && newStep != 1) {
              location.assign("/checkout");
          } else {
              if(!this.isBasket && newStep === 1) {
                location.assign("/basket");
              } else if (newStep < 4) {
                this.activeStep = newStep;
              } else {
                this.activeStep = 4;
                //reloadPayPalPlusWall();
              }
          }
        },
        nextStep()
        {
          this.changeStep(this.activeStep + 1);
        },
        onCloseModal(event){
          this.activeStep = 2;
        },
        validateAddress(address) {
            if(address && address.address1 && address.address2 && address.name2 && address.name3 && address.postalCode && address.town) {
              for (const options of address.options) {
                if(options && options.typeId === 5 && options.value) {
                  return true;
                }
              }
            }
            return false;
        }
    }
}
</script>
